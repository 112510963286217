// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circularProgressCard_cardWrap__OcMTM {
  padding: 30px !important;
  border-radius: 10px !important;
  box-shadow: unset !important;
}

@media (max-width: 1439px) {
  .circularProgressCard_cardWrap__OcMTM {
    padding: 30px 20px !important;
  }
}

.circularProgressCard_tooltipContainer__U\\+UDR {
  position: relative;
  cursor: pointer;
}

.circularProgressCard_tooltip__sxtgx {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  color: black;
  padding: 28px 25px;
  z-index: 1;
  display: none;
  width: 280px;
  border-radius: 16px;
  border: px solid rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.circularProgressCard_tooltipContainer__U\\+UDR:hover .circularProgressCard_tooltip__sxtgx {
  display: block;
}

.circularProgressCard_tooltipContent__pLr8V {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/comps/CircularProgressCard/circularProgressCard.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,4BAA4B;AAC9B;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,yCAAyC;EACzC,qCAAqC;EACrC,kDAAkD;EAClD,kCAA0B;UAA1B,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".cardWrap {\n  padding: 30px !important;\n  border-radius: 10px !important;\n  box-shadow: unset !important;\n}\n\n@media (max-width: 1439px) {\n  .cardWrap {\n    padding: 30px 20px !important;\n  }\n}\n\n.tooltipContainer {\n  position: relative;\n  cursor: pointer;\n}\n\n.tooltip {\n  position: absolute;\n  right: 0;\n  top: calc(100% + 10px);\n  color: black;\n  padding: 28px 25px;\n  z-index: 1;\n  display: none;\n  width: 280px;\n  border-radius: 16px;\n  border: px solid rgba(255, 255, 255, 0.7);\n  background: rgba(255, 255, 255, 0.95);\n  box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(4px);\n}\n\n.tooltipContainer:hover .tooltip {\n  display: block;\n}\n\n.tooltipContent {\n  color: #000;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrap": `circularProgressCard_cardWrap__OcMTM`,
	"tooltipContainer": `circularProgressCard_tooltipContainer__U+UDR`,
	"tooltip": `circularProgressCard_tooltip__sxtgx`,
	"tooltipContent": `circularProgressCard_tooltipContent__pLr8V`
};
export default ___CSS_LOADER_EXPORT___;
